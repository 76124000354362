import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

const Wrapper = styled.div``;

export default observer(function FileDropzone({
  component: RenderedComponent = Wrapper,
  onSubmit = () => null,
  children,
  ...other
}) {
  function dropHandler(ev) {
    console.log("File(s) dropped");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    // Use DataTransferItemList interface to access the file(s)
    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
      // If dropped items aren't files, reject them
      if (ev.dataTransfer.items[i].kind === "file") {
        var file = ev.dataTransfer.items[i].getAsFile();
        console.log("... file[" + i + "].name = " + file.name);
        onSubmit(file);
      }
    }
  }

  function dragEnterHandler(ev) {
    console.log("File(s) entered drop zone");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    ev.dataTransfer.dropEffect = "copy";

    var file = ev.dataTransfer.items[0];
    var type = file.type.slice(0, file.type.indexOf("/"));
    console.log(type);
  }

  function dragOverHandler(ev) {
    console.log("File(s) in drop zone");

    // Prevent default behavior (Prevent file from being opened)
    ev.preventDefault();

    ev.dataTransfer.dropEffect = "copy";
  }

  return (
    <RenderedComponent
      onDrop={dropHandler}
      onDragEnter={dragEnterHandler}
      onDragOver={dragOverHandler}
      {...other}
    >
      {children}
    </RenderedComponent>
  );
});
