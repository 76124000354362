export const zoomLevel = {
  calculate() {
    var documentWidth = window.innerWidth;
    var documentHeight = window.innerHeight;
    // 1cm = 37.795276px;
    // 21cm width + 1cm of margins each sides
    // 29.7cm height + 1cm of margins each sides
    var zoomWidth = documentWidth / (23 * 37.795276);
    var zoomHeight = documentHeight / (31.7 * 37.795276);
    var zoomLevel = Math.min(zoomWidth, zoomHeight);
    // stop zooming when book fits page
    this._zoomLevel = zoomLevel >= 1 ? 1 : zoomLevel;
    return this._zoomLevel;
  },

  get value() {
    return this.calculate() || 1;
  },

  set value(val) {
    this._zoomLevel = val;
  },
};

const ScalingUtil = {
  zoomLevel,
};

export default ScalingUtil;
