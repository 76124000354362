import { makeObservable, observable, action } from "mobx";
import TEMPLATES from "../constants/TEMPLATES.const";

class AppStateStore {
  pageScale = 1;
  templateSelectorState = -1;
  templateConfig = TEMPLATES;

  constructor() {
    makeObservable(this, {
      pageScale: observable,
      templateSelectorState: observable,
      templateConfig: observable,
      setPageScale: action,
      setTemplateSelectorState: action,
      setTemplateConfig: action,
    });
  }

  setPageScale(newValue) {
    this.pageScale = newValue;
  }

  setTemplateSelectorState(pageIndex) {
    this.templateSelectorState = pageIndex;
  }

  setTemplateConfig(newTemplateConfig) {
    this.templateConfig = newTemplateConfig;
  }
}

export default new AppStateStore();
