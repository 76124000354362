import React from "react";
import styled from "styled-components";

const heightRatio = Math.sqrt(2);

const TinyPage = styled.div`
  width: 128px;
  height: ${128 * heightRatio}px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border: 1px #d3d3d3 solid;
  transition: width 250ms ease;
  position: relative;
`;

export default function PageThumbnail({
  background = "#FFF",
  children,
  style,
}) {
  // const opacity = isDragging ? 0 : 1;
  return <TinyPage style={style}>{children}</TinyPage>;
}
