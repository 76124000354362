import React, { useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

import RotateRightOutlinedIcon from "@material-ui/icons/RotateRightOutlined";
import RotateLeftOutlinedIcon from "@material-ui/icons/RotateLeftOutlined";
import DeleteForeverOutlinedIcon from "@material-ui/icons/DeleteForeverOutlined";

import PageStore from "../../store/Page.store";
import ImageUtil from "../../util/Image.util";

import FileDropzone from "../elements/FileDropzone";
import ImagePreview from "../elements/ImagePreview";
import EditorDraggable from "../elements/EditorDraggable";
import EditorControlZoom from "../elements/EditorControlZoom";
import { IMAGE_BASE } from "../../constants/TEMPLATES.const";

const EditorControls = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 999;
  display: none;
  white-space: nowrap;
  background: #fff;
  padding: 0 16px;
  box-shadow: 0 4px 5px rgba(75, 75, 75, 0.2);
  border: 1px #d3d3d3 solid;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
`;

const EditorControlIcon = styled.div`
  padding: 8px;
  cursor: pointer;
  color: #999;
  margin-left: 16px;

  &:hover {
    color: #000;
  }
`;

const Wrapper = styled.div`
  position: absolute;

  &:hover ${EditorControls} {
    display: flex;
  }
`;

export default observer(function ImageEditor({
  x,
  y,
  w,
  h,
  pageIndex,
  imageIndex,
  image,
}) {
  const selfRef = useRef(false);

  async function handleImage(file) {
    onChange(imageIndex, await ImageUtil.fileToBase64(file));
  }

  async function onChange(imageIndex, src) {
    console.log(imageIndex, src);
    const size = await ImageUtil.getImageSize(src);

    PageStore.updatePageImageByIndex(pageIndex, imageIndex, (image) => ({
      ...IMAGE_BASE,
      size,
      src,
    }));
  }

  function imageMoveHandler(offset) {
    const newOffset = {
      x: image.offset.x + offset.x / selfRef.current.offsetWidth,
      y: image.offset.y + offset.y / selfRef.current.offsetHeight,
    };
    // const newOffset = {
    //   x: Math.max(Math.min(0, (image.offset.x + offset.x) * image.zoom)),
    //   y: Math.max(Math.min(0, (image.offset.y + offset.y) * image.zoom)),
    // };

    PageStore.updatePageImageByIndex(pageIndex, imageIndex, (image) => ({
      ...image,
      offset: {
        x: newOffset.x,
        y: newOffset.y,
      },
      // offset: {
      //   x: image.offset.x + offset.x,
      //   y: image.offset.y + offset.y,
      // },
    }));
  }

  function imageZoomHandler(e) {
    PageStore.updatePageImageByIndex(pageIndex, imageIndex, (image) => ({
      ...image,
      zoom: e.target.value,
    }));
  }

  function imageRotateHandler(angle) {
    PageStore.updatePageImageByIndex(pageIndex, imageIndex, (image) => ({
      ...image,
      rotation: image.rotation + angle,
    }));
  }

  function imageDeleteHandler(e) {
    PageStore.updatePageImageByIndex(
      pageIndex,
      imageIndex,
      (image) => IMAGE_BASE
    );
  }

  return (
    <Wrapper
      ref={selfRef}
      style={{
        left: `${x}%`,
        top: `${y}%`,
        width: `${w}%`,
        height: `${h}%`,
      }}
    >
      <FileDropzone onSubmit={handleImage}>
        <EditorDraggable onChange={imageMoveHandler}>
          <ImagePreview image={image} />
        </EditorDraggable>
        <EditorControls>
          <EditorControlZoom onChange={imageZoomHandler} value={image.zoom} />
          <EditorControlIcon
            icon="rotate"
            onClick={() => imageRotateHandler(90)}
          >
            <RotateRightOutlinedIcon />
          </EditorControlIcon>
          <EditorControlIcon
            icon="rotate"
            onClick={() => imageRotateHandler(-90)}
          >
            <RotateLeftOutlinedIcon />
          </EditorControlIcon>
          <EditorControlIcon icon="trash" onClick={imageDeleteHandler}>
            <DeleteForeverOutlinedIcon />
          </EditorControlIcon>
        </EditorControls>
      </FileDropzone>
    </Wrapper>
  );
});
