import React, { useRef, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

const Wrapper = styled.div`
  background: #dfe0e4 center center no-repeat;
  /* cursor: grab; */
  position: relative;
  /* transition: all 250ms linear; */
  width: 100%;
  height: 100%;

  /* &:active {
    cursor: grabbing;
  } */
`;

const Preview = styled.img`
  position: relative;
  transition: transform 250ms ease;
`;

export default observer(function ImagePreview({ image, style }) {
  const selfRef = useRef();
  const scalingProp = image.size.width > image.size.height ? "width" : "height";
  const scalingRatio = image.size.width / image.size.height;

  return (
    <Wrapper
      hasImage={image.src ? true : false}
      ref={selfRef}
      // src={image.src}
      style={{
        // left: `${image.offset.x}px`,
        // top: `${image.offset.y}px`,
        // backgroundImage: image.src ? `url(${image.src})` : false,
        // backgroundSize: `${100 * widthRatio}% ${100}%`,
        // backgroundPosition: `${image.offset.x * 100}% ${image.offset.y * 100}%`,
        backgroundImage: `${image.src ? "none" : "/imagePlaceholder.png"}`,
        ...style,
      }}
    >
      <Preview
        style={{
          [scalingProp]: `${image.zoom * 100}%`,
          left: `${image.offset.x * 100}%`,
          top: `${image.offset.y * 100}%`,
          transform: `rotate(${image.rotation}deg)`,
          // transform: `rotate(${image.rotation}deg) scale(${1})`,
        }}
        src={image.src}
        alt=""
      />
    </Wrapper>
  );
});
