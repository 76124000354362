import React, { useState } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

const Wrapper = styled.div`
  position: absolute;
  background: #e0eaee center center no-repeat;
  /* outline: 2px solid #b7ccd3; */
  cursor: grab;
  overflow: hidden;
  width: 100%;
  height: 100%;

  &:active {
    cursor: grabbing;
  }
`;

export default observer(function EditorImagePositioning({
  children,
  onChange = () => null,
}) {
  const [lastMousePos, setLastMousePos] = useState(false);

  function calculateOffset(e) {
    return {
      x: e.pageX - lastMousePos.x,
      y: e.pageY - lastMousePos.y,
    };
  }

  function dragStartHandler(e) {
    e.preventDefault();
    setLastMousePos({
      x: e.pageX,
      y: e.pageY,
    });
  }

  function dragMoveHandler(e) {
    e.preventDefault();
    if (!lastMousePos) return;
    onChange(calculateOffset(e));
    setLastMousePos({
      x: e.pageX,
      y: e.pageY,
    });
  }

  function dragEndHandler(e) {
    e.preventDefault();
    if (!lastMousePos) return;
    setLastMousePos(false);
  }

  return (
    <Wrapper
      onMouseDown={dragStartHandler}
      onMouseMove={dragMoveHandler}
      onMouseUp={dragEndHandler}
      onMouseLeave={dragEndHandler}
    >
      {children}
    </Wrapper>
  );
});
