import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

export default observer(function EditorControls({
  onChange = () => null,
  onSubmit = () => null,
  value,
}) {
  return (
    <input
      onChange={onChange}
      onMouseUp={onSubmit}
      type="range"
      value={value}
      min="0.5"
      max="3"
      step="0.0001"
    />
  );
});
