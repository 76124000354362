import React, { useState } from "react";
import styled from "styled-components";
import ArrowDropDownOutlinedIcon from "@material-ui/icons/ArrowDropDownOutlined";
import ArrowDropUpOutlinedIcon from "@material-ui/icons/ArrowDropUpOutlined";

const Wrapper = styled.div`
  position: relative;
  min-width: 200px;
`;

const IconWrapper = styled.div`
  width: 64px;
  text-align: left;
`;

const ActiveLabel = styled.div`
  display: flex;
  align-items: center;
  border: 2px solid #dfe0e4;
  padding: 8px 16px;
  color: #616166;
  background: #fff;
  cursor: pointer;
  /* background: #f7f8fa; */

  &:hover {
    background: #f7f7f8;
  }
`;

const ItemWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  border: 2px solid #dfe0e4;
  border-top: none;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
  box-shadow: 0 4px 5px rgba(75, 75, 75, 0.2);
`;

const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  padding: 8px 16px;
  color: #616166;
  cursor: pointer;

  &:hover {
    background: #f7f7f8;
  }
`;

export default function Dropdown({
  items = [],
  value = 0,
  onChange = () => null,
}) {
  const [isOpen, setOpen] = useState(false);

  function itemChanged(newItem) {
    setOpen(false);
    onChange(newItem);
  }

  const activeItem = items[value];

  return (
    <Wrapper>
      <ActiveLabel
        onClick={() => setOpen(!isOpen)}
        style={{
          borderRadius: isOpen ? "8px 8px 0 0" : "8px",
        }}
      >
        <IconWrapper>{activeItem.icon}</IconWrapper>
        {activeItem.value}
        &nbsp;
        {isOpen ? <ArrowDropUpOutlinedIcon /> : <ArrowDropDownOutlinedIcon />}
      </ActiveLabel>
      <ItemWrapper
        style={{
          display: isOpen ? "block" : "none",
        }}
      >
        {items.map((item, index) => (
          <DropdownItem onClick={() => itemChanged(index)}>
            <IconWrapper>{item.icon}</IconWrapper>
            {item.value}
          </DropdownItem>
        ))}
      </ItemWrapper>
    </Wrapper>
  );
}
