const TEMPLATES = [
  {
    __v: 0,
    name: "Leere Seite",
    preview: "single",
    category: "layout",
    elements: [{ x: 0, y: 0, w: 0, h: 100 }],
  },
  {
    __v: 0,
    name: "1. Vollflächig links",
    preview: "single",
    category: "layout",
    elements: [{ x: 0, y: 0, w: 100, h: 100 }],
  },
  {
    __v: 0,
    name: "1. Vollflächig rechts",
    preview: "single",
    category: "layout",
    elements: [{ x: 0, y: 0, w: 100, h: 100 }],
  },
  {
    __v: 0,
    name: "2. 190mm Anschnitt innen links",
    preview: "single",
    category: "layout",
    elements: [{ x: 0, y: 0, w: 90.44, h: 100 }],
  },
  {
    __v: 0,
    name: "2. 190mm Anschnitt innen rechts",
    preview: "single",
    category: "layout",
    elements: [{ x: 9.56, y: 0, w: 90.44, h: 100 }],
  },
  {
    __v: 0,
    name: "3. 160mm Anschnitt innen links",
    preview: "single",
    category: "layout",
    elements: [{ x: 0, y: 0, w: 76.16, h: 100 }],
  },
  {
    __v: 0,
    name: "3. 160mm Anschnitt innen rechts",
    preview: "single",
    category: "layout",
    elements: [{ x: 23.84, y: 0, w: 76.16, h: 100 }],
  },
  {
    __v: 0,
    name: "4. 270mm Anschnitt überlappend links",
    preview: "double-left",
    category: "layout",
    layouts: [1],
    elements: [{ x: 0, y: 0, w: 128.52, h: 100 }],
  },
  {
    __v: 0,
    name: "4. 270mm Anschnitt überlappend rechts",
    preview: "double-right",
    category: "layout",
    layouts: [1],
    elements: [{ x: -28.52, y: 0, w: 128.52, h: 100 }],
  },
  {
    __v: 0,
    name: "5. 190mm Anschnitt innen 243mm hoch links",
    preview: "single",
    category: "layout",
    elements: [{ x: 0, y: 0, w: 90.44, h: 81.48 }],
  },
  {
    __v: 0,
    name: "5. 190mm Anschnitt innen 243mm hoch rechts",
    preview: "single",
    category: "layout",
    elements: [{ x: 9.56, y: 0, w: 90.44, h: 81.48 }],
  },
  {
    __v: 0,
    name: "6. 110mm Mittig ",
    preview: "single",
    category: "layout",
    elements: [{ x: 22.61, y: 0, w: 52.36, h: 100 }],
  },
  {
    __v: 0,
    name: "7. 2x 110mm Mittig ",
    preview: "single",
    category: "layout",
    elements: [
      { x: 27.37, y: 0, w: 52.36, h: 48.14 },
      { x: 27.37, y: 51.68, w: 52.36, h: 48.14 },
    ],
  },
  {
    __v: 0,
    name: "8. 175x267mm links",
    preview: "single",
    category: "layout",
    elements: [{ x: 7.14, y: 5.05, w: 83.3, h: 89.89 }],
  },
  {
    __v: 0,
    name: "8. 175x267mm rechts",
    preview: "single",
    category: "layout",
    elements: [{ x: 11.9, y: 5.05, w: 83.3, h: 89.89 }],
  },
  {
    __v: 0,
    name: "9. 143x183mm",
    preview: "single",
    category: "layout",
    elements: [{ x: 14.76, y: 10.1, w: 68.07, h: 61.62 }],
  },
  {
    __v: 0,
    name: "10. 110x143mm",
    preview: "single",
    category: "layout",
    elements: [{ x: 27.37, y: 20.2, w: 52.36, h: 48.13 }],
  },
  {
    __v: 0,
    name: "11. 82x110mm",
    preview: "single",
    category: "layout",
    elements: [{ x: 29.24, y: 20.2, w: 39.03, h: 37.04 }],
  },
  {
    __v: 0,
    name: "12. 2x 82x110mm",
    preview: "single",
    category: "layout",
    elements: [
      { x: 11.9, y: 20.2, w: 39.03, h: 37.04 },
      { x: 56.17, y: 20.2, w: 39.03, h: 37.04 },
    ],
  },
  {
    __v: 0,
    name: "13.2x 110x82mm",
    preview: "single",
    category: "layout",
    elements: [
      { x: 22.61, y: 20.2, w: 37.04, h: 37.04 },
      { x: 22.61, y: 51.52, w: 37.04, h: 37.04 },
    ],
  },
  {
    __v: 0,
    name: "14. 4x 110x110mm",
    preview: "single",
    category: "layout",
    elements: [
      { x: 27.37, y: 20.2, w: 25.34, h: 17.92 },
      { x: 54.39, y: 20.2, w: 25.34, h: 17.92 },
      { x: 27.37, y: 39.31, w: 25.34, h: 17.92 },
      { x: 54.39, y: 39.31, w: 25.34, h: 17.92 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 1",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 12.79, w: 43.55, h: 41.08 },
      { x: 53.79, y: 57.58, w: 25.49, h: 24.04 },
    ],
  },
  {
    __v: 0,
    name: "3-Bildraster 1",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 12.79, w: 43.55, h: 41.08 },
      { x: 27.61, y: 56.78, w: 19.65, h: 13.9 },
      { x: 54.45, y: 55, w: 16.6, h: 15.66 },
    ],
  },
  {
    __v: 0,
    name: "5-Bildraster 1",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 38.03, w: 43.55, h: 41.08 },
      { x: 27.61, y: 55, w: 17.84, h: 16.83 },
      { x: 27.61, y: 72.9, w: 17.84, h: 16.83 },
      { x: 51.66, y: 55, w: 17.84, h: 16.83 },
      { x: 51.66, y: 72.9, w: 17.84, h: 16.83 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 2",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.35, w: 58.07, h: 30.8 },
      { x: 28.13, y: 49.81, w: 28.13, h: 20.2 },
    ],
  },
  {
    __v: 0,
    name: "5-Bildraster 2",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 38.03, w: 43.55, h: 41.08 },
      { x: 27.61, y: 55, w: 17.84, h: 16.83 },
      { x: 27.61, y: 72.9, w: 17.84, h: 16.83 },
      { x: 51.66, y: 55, w: 17.84, h: 16.83 },
      { x: 51.66, y: 72.9, w: 17.84, h: 16.83 },
    ],
  },
  {
    __v: 0,
    name: "3-Bildraster 2",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.37, w: 43.55, h: 41.07 },
      { x: 27.61, y: 57.57, w: 21.4, h: 20.2 },
      { x: 50.05, y: 57.57, w: 21.4, h: 20.2 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 3",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 12.8, w: 43.55, h: 41.07 },
      { x: 27.61, y: 57.57, w: 43.63, h: 23.14 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 4",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 12.8, w: 43.55, h: 41.07 },
      { x: 27.61, y: 55, w: 20.4, h: 20.2 },
    ],
  },
  {
    __v: 0,
    name: "5-Bildraster 3",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 12.8, w: 43.55, h: 41.07 },
      { x: 27.61, y: 55, w: 17.84, h: 16.83 },
      { x: 54.74, y: 55, w: 17.84, h: 16.83 },
      { x: 27.61, y: 72.9, w: 17.84, h: 16.83 },
      { x: 54.74, y: 72.9, w: 17.84, h: 16.83 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 5",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.35, w: 49.16, h: 24.59 },
      { x: 27.61, y: 43.53, w: 18.6, h: 13.2 },
    ],
  },
  {
    __v: 0,
    name: "3-Bildraster 3",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.37, w: 49.16, h: 24.59 },
      { x: 27.61, y: 60.26, w: 21.4, h: 20.2 },
      { x: 50.57, y: 60.13, w: 28.61, h: 20.24 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 6",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.37, w: 43.55, h: 41.07 },
      { x: 27.61, y: 60.14, w: 21.4, h: 20.2 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 7.",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.37, w: 43.55, h: 41.07 },
      { x: 27.61, y: 60.14, w: 19.34, h: 18.25 },
    ],
  },
  {
    __v: 0,
    name: "3-Bildraster 4",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.35, w: 58.07, h: 30.8 },
      { x: 27.61, y: 49.8, w: 20.77, h: 20.2 },
      { x: 27.61, y: 60.14, w: 20.77, h: 20.2 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 8",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.37, w: 43.55, h: 41.07 },
      { x: 50.19, y: 60.14, w: 44.96, h: 20.2 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 9",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.37, w: 43.55, h: 41.07 },
      { x: 27.61, y: 60.14, w: 21.76, h: 15.39 },
    ],
  },
  {
    __v: 0,
    name: "4-Bildraster 1",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.35, w: 58.07, h: 30.8 },
      { x: 27.62, y: 52.33, w: 13.23, h: 14.04 },
      { x: 40.84, y: 47.23, w: 27.1, h: 19.17 },
      { x: 68.04, y: 58.14, w: 11.69, h: 8.27 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 10",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.35, w: 58.07, h: 30.8 },
      { x: 27.61, y: 49.8, w: 18.84, h: 20.2 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 11",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.35, w: 58.07, h: 30.8 },
      { x: 68.66, y: 49.8, w: 11.85, h: 23.57 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 12",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.35, w: 58.07, h: 30.8 },
      { x: 27.61, y: 47.24, w: 21.2, h: 23.57 },
    ],
  },
  {
    __v: 0,
    name: "3-Bildraster 5",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.35, w: 58.07, h: 30.8 },
      { x: 27.61, y: 49.8, w: 21.4, h: 20.2 },
      { x: 59.29, y: 53.96, w: 16.42, h: 15.96 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 13",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.35, w: 58.07, h: 30.8 },
      { x: 27.61, y: 49.8, w: 21.42, h: 20.2 },
    ],
  },
  {
    __v: 0,
    name: "2-Bildraster 14",
    preview: "single",
    category: "mood",
    elements: [
      { x: 27.61, y: 15.35, w: 58.07, h: 30.8 },
      { x: 27.61, y: 49.8, w: 21.4, h: 20.2 },
    ],
  },
];

export const IMAGE_BASE = {
  __v: 0,
  src: "",
  size: { width: 0, height: 0 },
  offset: { x: 0, y: 0 },
  zoom: 1,
  rotation: 0,
};

export default TEMPLATES;
