import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

const Wrapper = styled.div`
  position: absolute;
  background: #dfe0e4;
  overflow: hidden;
`;

export default observer(function ImageWrapper({ elementData, children }) {
  if (!elementData) return null;

  return (
    <Wrapper
      style={{
        left: `${elementData.x}%`,
        top: `${elementData.y}%`,
        width: `${elementData.w}%`,
        height: `${elementData.h}%`,
      }}
    >
      {children}
    </Wrapper>
  );
});
