export async function fileToBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

export async function getImageSize(image) {
  return new Promise((resolve, reject) => {
    try {
      var i = new Image();

      i.onload = function () {
        resolve({ width: i.width, height: i.height });
      };

      i.src = image;
    } catch (err) {
      reject(err);
    }
  });
}

const ImageUtil = {
  fileToBase64,
  getImageSize,
};

export default ImageUtil;
