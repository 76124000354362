import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

import TemplatePage from "../components/modules/TemplatePage";

import PageStore from "../store/Page.store";

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.layout === 1 ? "row" : "column")};
  flex-wrap: ${(props) => (props.layout === 1 ? "wrap" : "nowrap")};
  margin-top: 96px;
`;

export default observer(function Overview() {
  return (
    <Wrapper layout={PageStore.layout}>
      {PageStore.pages.map((pageData, index) => (
        <TemplatePage pageData={pageData} index={index} key={index} />
      ))}
    </Wrapper>
  );
});
