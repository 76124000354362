import React, { useCallback, useContext } from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import DeleteOutlinedIcon from "@material-ui/icons/DeleteOutlined";

// import TEMPLATES from "../../constants/TEMPLATES.const";
import AppStateStore from "../../store/AppState.store";
import PageStore from "../../store/Page.store";

import PagePreview from "../modules/PagePreview";
import Sortable from "../elements/Sortable";

const PageAction = styled.div`
  text-align: center;
  border: 1px solid #ccc;
  background: #fff;
  border-radius: 2rem;
  font-size: 14px;
  color: #999;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;

  &:hover {
    color: #333;
  }
`;

const Smalltext = styled.div`
  font-size: 12px;
  margin-top: 4px;
  color: #999;
`;

const PageActionWrapper = styled.div`
  opacity: 0;
  z-index: 999;
  height: 120%;
  width: 140%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  top: -10%;
  left: -20%;
  cursor: grab;
`;

const ThumbnailWrapper = styled.div`
  padding: 32px;
  transition: padding 250ms ease;

  &:hover {
    ${PageActionWrapper} {
      opacity: 1;
      cursor: pointer;
    }
  }
`;

const SortWrapper = styled.div`
  display: inline-block;
`;

const TwoPageWrapper = styled.div``;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;

  ${SortWrapper}:nth-child(odd) {
    page-break-after: ${(props) => (props.layout === 1 ? "always" : "auto")};
    break-after: ${(props) => (props.layout === 1 ? "always" : "auto")};

    ${ThumbnailWrapper} {
      padding-right: ${(props) => (props.layout === 1 ? 0 : "32px")};
    }
  }

  ${SortWrapper}:nth-child(even) {
    page-break-after: ${(props) => (props.layout === 1 ? "avoid" : "auto")};
    break-after: ${(props) => (props.layout === 1 ? "avoid" : "auto")};

    ${ThumbnailWrapper} {
      padding-left: ${(props) => (props.layout === 1 ? 0 : "32px")};
    }
  }
`;

export default observer(function SortablePageOrder({
  store,
  children,
  selectTemplate = () => null,
}) {
  const movePage = useCallback(
    (dragIndex, hoverIndex) => {
      const dragCard = store.pages[dragIndex];

      store.movePage(dragIndex, hoverIndex);
    },
    [store.pages]
  );

  const renderPagePreview = (data, index) => {
    function deletePage() {
      store.removePageByIndex(index);
    }

    function selectTemplate() {
      console.log(index);
      AppStateStore.setTemplateSelectorState(index + 1);
    }

    return (
      <SortWrapper>
        <Sortable
          key={index}
          index={index}
          id={index}
          onMove={movePage}
          type="page"
        >
          <ThumbnailWrapper>
            <PagePreview
              // id={card.id}
              index={index}
              background={data.color}
            >
              <PageActionWrapper>
                <PageAction onClick={selectTemplate}>
                  <EditOutlinedIcon fontSize="small" />
                  &nbsp; Template auswählen
                </PageAction>
                {/* <br />
            ID: {data.id}
            <br />
            Template: <br />
            {TEMPLATES[data.template].name}
            {AppStateStore.templateConfig[data.template].name}
            <br />
            <br /> */}
                <PageAction onClick={deletePage}>
                  <DeleteOutlinedIcon fontSize="small" />
                  &nbsp; Seite Löschen
                </PageAction>
              </PageActionWrapper>
            </PagePreview>
            <Smalltext>Seite {index + 1}</Smalltext>
          </ThumbnailWrapper>
        </Sortable>
      </SortWrapper>
    );
  };

  return (
    <Wrapper layout={PageStore.layout}>
      <DndProvider backend={HTML5Backend}>
        {store.pages
          .reduce((total, curr, idx) => {
            // return renderPagePreview(curr, idx);
            if (PageStore.layout == 1) {
              const elem = renderPagePreview(curr, idx);
              idx % 2 == 0
                ? total.push([elem])
                : total[total.length - 1].push(elem);
            } else {
              total.push(renderPagePreview(curr, idx));
            }
            return total;
          }, [])
          .map((group) => (
            <TwoPageWrapper>{group}</TwoPageWrapper>
          ))}
        {/* {store.pages.map((data, index) => {
          return (
            <>
              {renderPagePreview(data, index)}
              {/* {index % 2 == 1 && <>&shy;</>} 
            </>
          );
        })} */}
      </DndProvider>
      {children}
    </Wrapper>
  );
});
