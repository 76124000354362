import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

import TEMPLATES from "../../constants/TEMPLATES.const";
import PageStore from "../../store/Page.store";
import AppStateStore from "../../store/AppState.store";

import ImageEditor from "../modules/ImageEditor";

const DinPage = styled.div`
  /* width: 21cm;
  height: 29.7cm; */
  /* padding: 2cm; */
  margin: 1cm auto;
  width: ${(21 * 80) / 29.7}vmin;
  height: 80vmin;
  background: white;
  box-shadow: 0 4px 5px rgba(75, 75, 75, 0.2);
  border: 1px #d3d3d3 solid;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  position: relative;
`;

const Wrapper = styled.div`
  flex: ${(props) => (props.layout === 1 ? "0 0 50%" : false)};

  &:nth-child(2n + 1) {
    ${DinPage} {
      margin-right: ${(props) => (props.layout === 1 ? 0 : "auto")};
    }
  }

  &:nth-child(2n) {
    ${DinPage} {
      margin-left: ${(props) => (props.layout === 1 ? 0 : "auto")};
    }
  }
`;

export default observer(function TemplatePage({ index }) {
  // const onToggleCompleted = () => {
  //   todo.completed = !todo.completed;
  // };

  // const onRename = () => {
  //   todo.task = prompt("Task name", todo.task) || todo.task;
  // };

  const templateData = TEMPLATES[PageStore.pages[index].template];
  // const templateData =
  //   AppStateStore.templateConfig[PageStore.pages[index].template];

  const imageElems = templateData.elements.map((elemData, idx) => {
    const imageData = PageStore.pages[index].images[idx];
    return (
      <ImageEditor
        key={idx}
        pageIndex={index}
        imageIndex={idx}
        image={imageData}
        {...elemData}
      />
    );
  });

  return (
    <Wrapper
      layout={PageStore.layout}
      style={templateData.style ? templateData.style : null}
    >
      <DinPage>
        {/* {content.template} */}
        {imageElems}
        {/* <div onDoubleClick={onRename}>
        <input
          type="checkbox"
          checked={todo.completed}
          onChange={onToggleCompleted}
        />
        {todo.task}
        {todo.assignee ? <small>{todo.assignee.name}</small> : null}
      </div> */}
      </DinPage>
    </Wrapper>
  );
});
