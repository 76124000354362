import { makeObservable, observable, computed, autorun, action } from "mobx";
// import TEMPLATES, { IMAGE_BASE } from "../constants/TEMPLATES.const";
import { IMAGE_BASE } from "../constants/TEMPLATES.const";
import AppStateStore from "./AppState.store";

function getRandomColor() {
  var letters = "0123456789ABCDEF";
  var color = "#";
  for (var i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
}

function getRandomNumber(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

function uid() {
  return "xxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
}

class PageStore {
  pages = [];
  layout = 0;
  pendingRequests = 0;

  constructor() {
    makeObservable(this, {
      pages: observable,
      layout: observable,
      pendingRequests: observable,
      hydratePages: action,
      setLayout: action,
      addPage: action,
      removePageByIndex: action,
      updatePageByIndex: action,
      updatePageImageByIndex: action,
      movePage: action,
    });
    autorun(() => console.log(this));
    this.addPage();
  }

  hydratePages(pages) {
    this.pages = pages;
  }

  setLayout(layout) {
    this.layout = layout;
  }

  addPage(tpl) {
    const template = tpl || 0;
    // const images = new Array(TEMPLATES[template].elements.length).fill(
    //   IMAGE_BASE
    // );
    const images = new Array(
      AppStateStore.templateConfig[template].elements.length
    ).fill(IMAGE_BASE);

    this.pages.push({
      __v: 1,
      id: uid(),
      createdAt: new Date(),
      updatedAt: null,
      template,
      images,
      color: getRandomColor(),
    });
  }

  removePageByIndex(index) {
    this.pages.splice(index, 1);
  }

  updatePageByIndex(index, newValue) {
    if (typeof newValue === "function")
      this.pages[index] = newValue(this.pages[index]);
    else this.pages[index] = newValue;
  }

  updatePageImageByIndex(pageIndex, imageIndex, newValue) {
    this.updatePageByIndex(pageIndex, (page) => {
      if (typeof newValue === "function") {
        page.images[imageIndex] = newValue(page.images[imageIndex]);
      } else {
        page.images[imageIndex] = newValue;
      }

      return page;
    });
  }

  movePage(fromIndex, toIndex) {
    const dragCard = this.pages[fromIndex];

    // remove the dragged card
    this.pages.splice(fromIndex, 1);

    // add it back in at the hover position
    this.pages.splice(toIndex, 0, dragCard);

    // update the sort index
    this.pages.forEach((card, i) => (card.sortIndex = i));
  }
}

export default new PageStore();
