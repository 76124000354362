// Function to download data to a file
export function download(data, filename, type) {
  var file = new Blob([data], { type: type });
  if (window.navigator.msSaveOrOpenBlob)
    // IE10+
    window.navigator.msSaveOrOpenBlob(file, filename);
  else {
    // Others
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

export function readFile(e) {
  return new Promise((resolve, reject) => {
    var file = e.target.files[0];
    if (!file) {
      reject();
      return;
    }
    var reader = new FileReader();
    reader.onload = function (e) {
      var contents = e.target.result;
      resolve(contents);
    };
    reader.readAsText(file);
  });
}

const FileUtil = {
  download,
  readFile,
};

export default FileUtil;
