import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

// import { IMAGE_BASE } from "../../constants/TEMPLATES.const";
import PageStore from "../../store/Page.store";
import AppStateStore from "../../store/AppState.store";

import PageThumbnail from "../elements/PageThumbnail";
import ImagePreview from "../elements/ImagePreview";
import ImageWrapper from "../elements/ImageWrapper";

export default observer(function PagePreview({ index, children }) {
  const templateData =
    AppStateStore.templateConfig[PageStore.pages[index].template];

  const imageElems = templateData.elements.map((elemData, idx) => {
    const imageData = PageStore.pages[index].images[idx];

    return (
      <ImageWrapper index={idx} elementData={elemData}>
        <ImagePreview
          key={idx}
          pageIndex={index}
          imageIndex={idx}
          image={imageData}
        />
      </ImageWrapper>
    );
  });

  return (
    <PageThumbnail style={templateData.style ? templateData.style : null}>
      {imageElems}
      {children}
    </PageThumbnail>
  );
});
