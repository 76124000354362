import React from "react";
import styled, { css } from "styled-components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  NavLink,
} from "react-router-dom";
import { observer } from "mobx-react-lite";
import AppsOutlined from "@material-ui/icons/AppsOutlined";
import AddPhotoAlternateOutlined from "@material-ui/icons/AddPhotoAlternateOutlined";
import InsertDriveFileOutlinedIcon from "@material-ui/icons/InsertDriveFileOutlined";
import CloudDownloadOutlinedIcon from "@material-ui/icons/CloudDownloadOutlined";
import FolderOpenOutlinedIcon from "@material-ui/icons/FolderOpenOutlined";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";

import Editor from "./pages/Editor";
import Overview from "./pages/Overview";
import Templates from "./pages/Templates";
import PageStore from "./store/Page.store";
import Dropdown from "./components/elements/Dropdown";
import AppStateStore from "./store/AppState.store";
import FileUtil, { download, readFile } from "./util/File.util";

// import Debug from "./Debug";

// const DebugWrapper = styled.nav`
//   max-width: 100%;
// `;

const Nav = styled.nav`
  position: fixed;
  z-index: 9999;
  padding: 16px 32px;
  background-color: #fff;
  border-bottom: 1px solid #b9b9b9;
  width: 100%;
  top: 0;
  display: flex;
  justify-content: space-between;
  display: flex;
  align-items: center;
`;

const Tab = css`
  color: #acadb4;
  padding: 8px 16px;
  font-size: 14px;
  display: inline-flex;
  align-items: flex-end;
  text-decoration: none;
  border-left: 2px solid transparent;
  border-right: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const NavTab = styled(NavLink)`
  ${Tab}

  &.selected {
    border-left-color: #dfe0e4;
    border-right-color: #dfe0e4;
    background: #fff;
    color: #616166;
    border-radius: 8px;
  }

  &:first-child {
    border-left: 2px solid #dfe0e4;
    border-radius: 8px;
  }

  &:last-child {
    border-right: 2px solid #dfe0e4;
    border-radius: 8px;
  }
`;

const TabGroup = styled.div`
  border-top: 2px solid #dfe0e4;
  border-bottom: 2px solid #dfe0e4;
  border-radius: 10px;
  background: #f7f8fa;
  display: flex;

  &::last-child {
    border-right: 2px solid #dfe0e4;
  }
`;

const MainWrapper = styled.div`
  margin-top: 64px;
`;

const AppAction = styled.div`
  ${Tab}
  background: #fff;

  &:hover {
    cursor: pointer;
    color: #616166;
  }
`;

const NavLabel = styled.div`
  margin-top: 8px;
`;

const AppActionWrapper = styled.div`
  /* border-top: 2px solid #dfe0e4;
  border-bottom: 2px solid #dfe0e4; */
  display: flex;
`;

const MainNavWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  margin-right: 32px;
  height: 40px;
`;

export default observer(function App() {
  const savePages = () => {
    const filename = prompt("Name der Datei", "Unbenannt");

    if (filename == null || filename == "") return;

    download(
      JSON.stringify({
        pages: PageStore.pages,
        templates: AppStateStore.templateConfig,
        layout: PageStore.layout,
      }),
      `${filename}.dwlt`,
      "application/json"
    );
    // localStorage.setItem("layout", JSON.stringify(PageStore.pages));
  };

  const loadPages = async (e) => {
    const fileData = JSON.parse(await readFile(e));
    PageStore.hydratePages(fileData.pages);
    PageStore.setLayout(fileData.layout);
  };

  const reset = () => {
    var r = window.confirm(
      "Sicher? Alle ungesicherten Änderungen gehen verloren!"
    );
    if (r === true) {
      PageStore.hydratePages([]);
      PageStore.setLayout(0);
      PageStore.addPage();
    }
  };

  function layoutChangeHandler(newLayout) {
    PageStore.setLayout(newLayout);
    if (newLayout === 1 && PageStore.pages.length % 2 == 1) PageStore.addPage();
  }

  return (
    <Router>
      <Nav>
        <MainNavWrapper>
          <Logo src="/logo.png" />
          <TabGroup>
            <NavTab activeClassName="selected" exact to="/">
              <AppsOutlined />
              <NavLabel>Übersicht</NavLabel>
            </NavTab>
            <NavTab activeClassName="selected" exact to="/editor">
              <AddPhotoAlternateOutlined />
              <NavLabel>Foto Editor</NavLabel>
            </NavTab>
            {/* <NavTab activeClassName="selected" to="/templates">
            Templates
          </NavTab> */}
          </TabGroup>
        </MainNavWrapper>
        <Dropdown
          onChange={layoutChangeHandler}
          value={PageStore.layout}
          items={[
            {
              key: "single",
              value: "Einzelseiten",
              icon: (
                <div>
                  <InsertDriveFileOutlinedIcon fontSize="small" />
                </div>
              ),
            },
            {
              key: "double",
              value: "Doppelseiten",
              icon: (
                <div>
                  <InsertDriveFileOutlinedIcon fontSize="small" />
                  <InsertDriveFileOutlinedIcon fontSize="small" />
                </div>
              ),
            },
          ]}
        />
        <AppActionWrapper>
          <AppAction onClick={reset}>
            <AddBoxOutlinedIcon />
            <NavLabel>Neue Datei</NavLabel>
          </AppAction>
          <AppAction onClick={savePages}>
            <CloudDownloadOutlinedIcon />
            <NavLabel>Datei speichern</NavLabel>
          </AppAction>
          <AppAction
            onClick={() => document.getElementById("file-input").click()}
          >
            <FolderOpenOutlinedIcon />
            <NavLabel>Datei öffnen</NavLabel>
          </AppAction>
          <input
            onChange={loadPages}
            id="file-input"
            type="file"
            name="filename"
            accept=".dwlt"
            style={{
              display: "none",
            }}
          />
        </AppActionWrapper>
      </Nav>
      <MainWrapper>
        <Switch>
          <Route exact path="/editor">
            <Editor />
          </Route>
          <Route exact path="/templates">
            <Templates />
          </Route>
          <Route exact path="/">
            <Overview />
          </Route>
        </Switch>
      </MainWrapper>
      {/* <DebugWrapper>
        <Debug />
      </DebugWrapper> */}
    </Router>
  );
});
