import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import appStateStore from "./store/AppState.store";
import ScalingUtil from "./util/Scaling.util";
// import reportWebVitals from "./reportWebVitals";

import "./index.css";

appStateStore.setPageScale(ScalingUtil.zoomLevel.calculate());
window.addEventListener("resize", () =>
  appStateStore.setPageScale(ScalingUtil.zoomLevel.calculate())
);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
