import React from "react";
import styled from "styled-components";
import AddOutlinedIcon from "@material-ui/icons/AddOutlined";

import PageThumbnail from "../components/elements/PageThumbnail";
import SortablePageOrder from "../components/modules/SortablePageOrder";
import TemplateModal from "../components/modules/TemplateModal";

import PageStore from "../store/Page.store";

const Wrapper = styled.div`
  margin-top: 128px;
`;

const ThumbnailWrapper = styled.div`
  margin: 32px;
  cursor: pointer;
  font-size: 14px;
  color: #999;
`;

export default function Overview() {
  const onNewPage = () => {
    PageStore.addPage();
    if (PageStore.layout === 1 && PageStore.pages.length % 2 === 1)
      PageStore.addPage();
  };

  return (
    <Wrapper>
      <TemplateModal />
      <SortablePageOrder store={PageStore}>
        <ThumbnailWrapper onClick={onNewPage}>
          <PageThumbnail
            style={{
              width: PageStore.layout === 1 ? "256px" : "128px",
              boxShadow: "none",
              border: "none",
            }}
          >
            <AddOutlinedIcon fontSize="large" />
            <br />
            Seite hinzufügen
          </PageThumbnail>
        </ThumbnailWrapper>
      </SortablePageOrder>
    </Wrapper>
  );
}
