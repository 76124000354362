import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";

import AppStateStore from "../store/AppState.store";

const Wrapper = styled.div`
  margin-top: 96px;
  text-align: center;
  height: 100%;
`;

const TemplateConfig = styled.textarea`
  width: 80%;
  margin: 0 auto;
  height: 80vh;
`;

export default observer(function Templates() {
  function templateChanged(e) {
    AppStateStore.setTemplateConfig(JSON.parse(e.target.value));
  }

  return (
    <Wrapper>
      <TemplateConfig
        onChange={templateChanged}
        defaultValue={JSON.stringify(AppStateStore.templateConfig, null, 4)}
      ></TemplateConfig>
    </Wrapper>
  );
});
