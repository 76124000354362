import React from "react";
import styled from "styled-components";
import { observer } from "mobx-react-lite";
import Modal from "react-modal";

import CloseOutlinedIcon from "@material-ui/icons/CloseOutlined";

// import TEMPLATES, { IMAGE_BASE } from "../../constants/TEMPLATES.const";
import { IMAGE_BASE } from "../../constants/TEMPLATES.const";
import PageStore from "../../store/Page.store";
import AppStateStore from "../../store/AppState.store";

import PageThumbnail from "../elements/PageThumbnail";
import ImageWrapper from "../elements/ImageWrapper";

const Header = styled.div`
  background: #f7f7f8;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
`;
const Headline = styled.div`
  font-size: 24px;
  color: #616166;
`;
const CloseTemplate = styled.div`
  color: #616166;
  cursor: pointer;
`;

const TemplateList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  height: 90%;
`;

const TemplateTitle = styled.div`
  font-size: 12px;
  margin-top: 8px;
`;

const ThumbWrapper = styled.div`
  margin: 16px;

  &:hover {
    cursor: pointer;
  }
`;

const TemplatePreview = styled.div`
  display: flex;
`;

export default observer(function TemplateModal() {
  const isVisible = AppStateStore.templateSelectorState > 0;

  console.log("Updated", isVisible, AppStateStore.templateSelectorState);

  function setTemplateForPage(template) {
    PageStore.updatePageByIndex(
      AppStateStore.templateSelectorState - 1,
      (page) => {
        // Remove or add images when the template changes
        // const currentImageAmount = page.images.length;
        // const newImageAmount = TEMPLATES[template].elements.length;
        // if (newImageAmount > currentImageAmount)
        //   page.images.fill(IMAGE_BASE, currentImageAmount);
        // else if (currentImageAmount > newImageAmount)
        //   page.images.splice(newImageAmount);
        page.images = new Array(
          AppStateStore.templateConfig[template].elements.length
        ).fill(IMAGE_BASE);
        return { ...page, template };
      }
    );
    closeSelf();
  }

  function closeSelf() {
    AppStateStore.setTemplateSelectorState(-1);
  }

  function renderPage(templateData) {
    return (
      <PageThumbnail style={templateData.style ? templateData.style : null}>
        {templateData.elements.map((elementData, elementIdx) => (
          <ImageWrapper key={elementIdx} elementData={elementData} />
        ))}
      </PageThumbnail>
    );
  }

  // 468

  function renderTemplate(templateData) {
    let tpl = [];
    if (templateData.preview === "double-right") tpl.push(<PageThumbnail />);
    tpl.push(renderPage(templateData));
    if (templateData.preview === "double-left") tpl.push(<PageThumbnail />);
    return (
      <TemplatePreview
        style={{
          margin: "0 auto",
          minWidth:
            templateData.preview === "double-right" ||
            templateData.preview === "double-left"
              ? 290
              : false,
        }}
      >
        {tpl}
      </TemplatePreview>
    );
  }

  return (
    <Modal
      style={{
        overlay: {
          zIndex: 9999,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        },

        content: {
          padding: 0,
          border: "none",
          top: "64px",
          maxWidth: "75%",
          maxHeight: "80%",
          margin: "0 auto",
        },
      }}
      ariaHideApp={false}
      isOpen={isVisible}
      // onRequestClose={onClose}
    >
      <Header>
        <Headline>Template auswählen</Headline>
        <CloseTemplate onClick={closeSelf}>
          <CloseOutlinedIcon />
        </CloseTemplate>
      </Header>
      <TemplateList>
        {/* {TEMPLATES.map((value, index) => { */}
        {AppStateStore.templateConfig
          .slice()
          .map((templateData, templateIdx) => {
            if (
              templateData.layouts &&
              templateData.layouts.indexOf(PageStore.layout) === -1
            )
              return;
            return (
              <ThumbWrapper
                key={templateIdx}
                onClick={() => setTemplateForPage(templateIdx)}
              >
                {renderTemplate(templateData)}
                <TemplateTitle>{templateData.name}</TemplateTitle>
              </ThumbWrapper>
            );
          })}
      </TemplateList>
    </Modal>
  );
});
